#sidebar {
    box-sizing: border-box;
    height: 100vh;
    background-color: #F7F9FB;
    padding: 0.95rem 1.5rem 1rem 1.2rem;
    position: sticky;
    top: 0;
    flex: 1;
    /* Flexible width */
    max-width: 20rem;
    /* Expanded state */
    transition: max-width 0.5s ease-in-out, padding 0.5s ease-in-out;
    /* Smooth animation */
    overflow: hidden;
    box-shadow: 2px 0px 4px #0000001A;
    margin-right: 0.2rem;
}

#sidebar.collapsed {
    max-width: 10rem;
    padding: 0.95rem 0.5rem 1rem 0.8rem;
    /* Collapsed state */
}

.logo {
    display: flex;
}

.logo-img {
    margin-right: 0.2rem;
    width: 2.8rem;
}

.logo-sfi {
    margin-right: 0.2rem;
    font-family: "Din condensed";
    font-weight: 400;
    font-size: 2.4rem;
    color: #344F51;

}

.logo-data {
    font-family: "DINCond-light";
    font-weight: 300;
    font-size: 2.35rem;
    color: #344F51;
    margin-right: 5rem;
}

.menu-icon {
    margin-left: 1rem;
    cursor: pointer;
}

.menu-margin {
    margin: 2.5rem auto;
}

.menu {
    font-family: "GT Walsheim Trial Bd";
    font-size: 1rem;
    font-weight: 700;
    color: #000000;
    opacity: 50%;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.menu-text {
    margin-left: 1.2rem;
}



.dropdown-menu {
    font-family: "GT Walsheim Trial Bd";
    font-size: 1rem;
    font-weight: 700;
    color: #000000;
    opacity: 50%;
    padding: 0;
    border: none;
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: space-between;

}



.arrow {
    transition: transform 0.3s ease;
}

.arrow-rotate {
    transform: rotate(180deg);
}

#sidebar ul {
    list-style: none;
    padding: 0;
}

#sidebar .sub-menu {
    display: grid;
    grid-template-rows: 0fr;
    transition: 300ms ease-in-out;

    /* The menu is initially collapsed */
    >div {

        margin-left: 3.5rem;
        overflow: hidden;
    }
}

#sidebar .sub-menu.show {
    grid-template-rows: 1fr;
}

.sub-menu-margin {
    margin-top: 1.9rem;
}

.sub-menu-text {
    display: block;
    text-decoration: none;
    font-family: "GT Walsheim Trial Bd";
    font-size: 1rem;
    font-weight: 700;
    color: #000000;
    opacity: 50%;
}

.sub-menu-icon {
    display: none;
    opacity: 50%;
}

.active {
    opacity: 100%;
}

@media only screen and (max-width:47.95rem) {
    #sidebar {
        padding: 0.95rem 0.5rem 1rem 0.8rem;
    }

    #sidebar .sub-menu {
        >div {
            margin-left: 0.5rem;
        }
    }

    .logo-sfi {
        display: none;
    }

    .logo-data {
        display: none;
    }

    .menu-icon {
        display: none;
    }

    .menu-text {
        display: none;
    }

    .sub-menu-text {
        display: none;
    }

    .sub-menu-icon {
        display: block;
    }
}