.mid-point {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F7F9FB;
}

.loader {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: transparent;
    animation: rotate 4s steps(8) infinite;

}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.box {
    position: absolute;
    width: 21px;
    height: 21px;
    opacity: 1;
}

.first {
    background-color: #344F51;
    top: 0;
    left: 0;
    border-radius: 3px;
}

.second {
    background-color: #83A98C;
    top: 0;
    right: 0;
    border-radius: 3px;
}

.third {
    background-color: #83A98C;
    bottom: 0;
    left: 0;
    border-radius: 3px;
}

.fourth {
    background-color: #344F51;
    bottom: 0;
    right: 0;
    border-radius: 3px;
}

/* Border Animation */

.first {
    animation: borderChange 4s steps(8) infinite;
}

/* Animation for First & Third */
/* Animation for First */
@keyframes borderChange {
    0% {
        border-radius: 3px;
        top: 15px;
        left: 15px;
    }

    1% {
        top: 0;
        left: 0;
    }

    12.5% {
        border-radius: 3px;
    }

    25% {
        border-radius: 3px;
    }

    26% {
        border-radius: 50%;
    }

    37.5% {
        border-radius: 50%;
    }

    38% {
        border-radius: 3px;
    }

    49% {
        border-radius: 3px;
    }

    /* Moves to center */
    50% {
        border-radius: 3px;

    }

    60% {
        border-radius: 3px;
        top: 0;
        left: 0;
    }

    61% {
        border-radius: 3px;
        top: 15px;
        left: 15px;
    }

    /* Moves back */
    62.5% {
        border-radius: 3px;
        top: 0;
        left: 0;
    }

    74% {
        border-radius: 3px;
    }

    75% {
        border-radius: 50%;
    }

    86% {
        border-radius: 50%;
    }

    87.5% {
        border-radius: 3px;
    }

    99% {
        border-radius: 3px;
    }

    100% {
        border-radius: 50%;
    }
}

.fourth {
    animation: fourthbox 4s steps(8) infinite;
}

@keyframes fourthbox {
    0% {
        border-radius: 3px;
        bottom: 15px;
        right: 15px;
    }

    1% {
        bottom: 0;
        right: 0;
    }

    12.5% {
        border-radius: 3px;
    }

    25% {
        border-radius: 3px;
    }

    26% {
        border-radius: 50%;
    }

    37.5% {
        border-radius: 50%;
    }

    38% {
        border-radius: 3px;
    }

    49% {
        border-radius: 3px;
    }

    /* Moves to center */
    50% {
        border-radius: 3px;

    }

    60% {
        border-radius: 3px;
        bottom: 0;
        right: 0;
    }

    61% {
        border-radius: 3px;
        bottom: 15px;
        right: 15px;
    }

    /* Moves back */
    62.5% {
        border-radius: 3px;
        bottom: 0;
        right: 0;
    }

    74% {
        border-radius: 3px;
    }

    75% {
        border-radius: 50%;
    }

    86% {
        border-radius: 50%;
    }

    87.5% {
        border-radius: 3px;
    }

    99% {
        border-radius: 3px;
    }

    100% {
        border-radius: 50%;
    }
}

.second {
    animation: secondbox 4s steps(8) infinite;
}

@keyframes secondbox {
    0% {
        border-radius: 3px;
        top: 15px;
        right: 15px;
    }

    1% {
        top: 0;
        right: 0;
    }

    12.5% {
        border-radius: 3px;
    }

    25% {
        border-radius: 3px;
    }

    26% {
        border-radius: 3px;
    }

    37.5% {
        border-radius: 3px;
    }

    38% {
        border-radius: 3px;
    }

    49% {
        border-radius: 3px;
    }

    /* Moves to center */
    50% {
        border-radius: 50%;

    }

    60% {
        border-radius: 50%;
        top: 0;
        right: 0;
    }

    61% {
        border-radius: 3px;
        top: 15px;
        right: 15px;
    }

    /* Moves back */
    62.5% {
        border-radius: 3px;
        top: 0;
        right: 0;
    }

    74% {
        border-radius: 3px;
    }

    75% {
        border-radius: 50%;
    }

    86% {
        border-radius: 50%;
    }

    87.5% {
        border-radius: 3px;
    }

    99% {
        border-radius: 3px;
    }

    100% {
        border-radius: 50%;
    }
}

.third {
    animation: thirdbox 4s steps(8) infinite;
}

@keyframes thirdbox {
    0% {
        border-radius: 3px;
        bottom: 15px;
        left: 15px;
    }

    1% {
        bottom: 0;
        left: 0;
    }

    12.5% {
        border-radius: 3px;
    }

    25% {
        border-radius: 3px;
    }

    26% {
        border-radius: 3px;
    }

    37.5% {
        border-radius: 3px;
    }

    38% {
        border-radius: 3px;
    }

    49% {
        border-radius: 3px;
    }

    /* Moves to center */
    50% {
        border-radius: 50%;

    }

    60% {
        border-radius: 50%;
        bottom: 0;
        left: 0;
    }

    61% {
        border-radius: 3px;
        bottom: 15px;
        left: 15px;
    }

    /* Moves back */
    62.5% {
        border-radius: 3px;
        bottom: 0;
        left: 0;
    }

    74% {
        border-radius: 3px;
    }

    75% {
        border-radius: 50%;
    }

    86% {
        border-radius: 50%;
    }

    87.5% {
        border-radius: 3px;
    }

    99% {
        border-radius: 3px;
    }

    100% {
        border-radius: 50%;
    }
}