@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url("./assets/fonts/AvenirNextLTPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Demi.woff') format('woff'),
    url("./assets/fonts/AvenirNextLTPro-Demi.ttf") format("truetype"),
    url("./assets/fonts/AvenirNextLTPro-Demi.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: "DINCond-light";
  src: url("./assets/fonts/DINCond-Light.woff2") format("woff2"),
    url("./assets/fonts/DINCond-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Din condensed";
  src: url("./assets/fonts/din-condensed-regular.woff2") format("woff2"),
    url("./assets/fonts/din-condensed-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim Trial Bd";
  src: url("./assets/fonts/GT-Walsheim-Trial-Bd.woff2") format("woff2"),
    url("./assets/fonts/GT-Walsheim-Trial-Bd.woff") format("woff"),
    url("./assets/fonts/GT-Walsheim-Trial-Bd.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}


:root {
  --primary-font: 'Avenir Next LT Pro';
  --secondery-font: "GT Walsheim Trial Bd";
  --primary-color: #344F51;
  --primary-bg-color: #83A98C33;
  --secondery-bg-color: #F7F9FB;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  /* 1rem = 16px */
}

body {
  margin: 0;
  font-family: 'Avenir Next LT Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Avenir Next LT Pro';
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}