.bg {
    position: sticky;
    top: 0;
    background-color: #F7F9FB;
    z-index: 5;
}

header {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
}


.path {
    font-family: "GT Walsheim Trial Bd";
    font-size: 0.9rem;
    font-weight: 700;
    margin: 0;
}

.icon {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1.5rem;

}

.icon li {
    cursor: pointer;
    position: relative;
}

.dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 12rem;
    background-color: #F7F9FB;
    padding: 0.4rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    z-index: 1000;
}

.notificationItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem;
}



.flex {
    display: flex;
    align-items: center;
}

.notification-text {
    font-family: var(--primary-font);
    font-size: 0.5rem;
    font-weight: 400;
    color: #2A2F33;
    margin-left: 0.5rem;
}

.notification-time {

    font-family: var(--primary-font);
    font-size: 0.5rem;
    font-weight: 400;
    color: #2A2F33;
    margin-left: 0.5rem;
    opacity: 50%;
}

.notification-image {
    width: 1.5rem;
    height: 1.5rem;
}

.notificationItem p {
    margin-bottom: 0;
}

.notificationItem:nth-child(odd) {
    background-color: #ffffff;
}

.notificationItem:nth-child(even) {
    background-color: #f5f5f5;
}

.active {
    opacity: 50%;
}

.setting {
    opacity: 50%;
    margin: 1.3rem;
}

.setting-image {
    width: 1rem;
    height: 1rem;
}

.setting-text {
    font-family: var(--primary-font);
    font-size: 0.9rem;
    font-weight: 400;
    margin-left: 0.5rem;
    margin-bottom: 0;
}

.setting:hover {
    opacity: 100%;
}

.logout {
    padding: 0.3rem 1.2rem;
    opacity: 50%;
}

.logout:hover {
    opacity: 100%;
    background-color: #ffffff;
}